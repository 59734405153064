const PROFILE = {
  GET_USERS: '/acc_manager/v1/users',
  CREATE_USER: '/acc_manager/v1/users/create',
  EDIT_USER: '/acc_manager/v1/users/edit',
  GET_PROFILE: '/acc_manager/v1/users/profile',
  GET_PROFILE_BY_EMAIL: '/acc_manager/v1/users/profile/by_email',
  DELETE_ACCOUNT: '/acc_manager/v1/users',
  GET_PROFILE_BY_ID: '/acc_manager/v1/users/profile/service',
  GENERATE_ONE_TIME_CODE: '/items_manager/v1/device/item',
  VALIDATE_DEVICE_CODE: '/items_manager/v1/device/item/user_code'
};

const ITEMS = {
  GET_ITEMS: '/items_manager/v1/items',
  GET_MODELS: '/items_manager/v1/models',
  MODELS_BASE: '/items_manager/v1/models', 
  ITEMS_BASE: '/items_manager/v1/items',
  CREATE_MODEL: '/items_manager/v1/models/create',
  SEARCH_MODELS: '/items_manager/v1/models/search',
  DELETE_MODEL: '/items_manager/v1/models',
  GET_MODEL: '/items_manager/v1/models',
  MODELS_CATEGORY: '/items_manager/v1/models/category',
  MODELS_COLLECTION: '/items_manager/v1/models/collection',
  MODELS_BRAND: '/items_manager/v1/models/brand',
  MODELS_MATERIAL: '/items_manager/v1/models/material',
  MODELS_TYPE: '/items_manager/v1/models/type',
  GET_FILTERS: '/items_manager/v1/models/filter',
  SEND_CODE: 'items_manager/v1/sales_manager/sales_code/send',
  SELL_ITEM: '/items_manager/v1/items/sell',
  CHECK_CODE: '/items_manager/v1/sales_manager/sales_code/validate',
  CREATE_USER_BY_DEALER: '/acc_manager/v1/sales_manager/users/create',
  SALES_DATA: '/items_manager/v1/items/sales_data',
  GET_COORDINATES: '/items_manager/v1/models/items/coordinates/model',
  ABILITY_BUY: '/items_manager/v1/items/ability_to_buy',
  ABILITY_SELL: '/items_manager/v1/items/sales_data/ability_to_sell',
  FAQ_SEND: '/items_manager/v1/faq/send',
  SEND_FINGERPRINTS_IMAGES: '/items_manager/v1/items',
  INVITE_BORROWER: '/items_manager/v1/loan/invite-borrower/send',
  ITEMS_LOAN: '/items_manager/v1/items/loan',
  CANCEL_LOAN: '/items_manager/v1/loan',
  BASE_LOAN: '/items_manager/v1/loan',
  GET_DRAFT: '/items_manager/v1/loan/draft/item_id',
  SET_CONNECT_DR: '/items_manager/v1/connection/connect/item',
  CHECK_OPEN_DR_CONNECT: '/items_manager/v1/connection/check_availability/item',
  DROP_DR_CONNECTION: '/items_manager/v1/connection/drop/item',
  GENERATE_ONE_TIME_CODE: '/items_manager/v1/device/',
  GET_ITEM_BY_TOKEN: '/items_manager/v1/items/by_token',
  ASSIGN_EXPERT: '/items_manager/v1/expertises',
  EXPERT_EVALUATIONS: '/items_manager/v1/expert_evaluations',
  GET_EXPERTISE_BY_ITEM_ID: '/items_manager/v1/expertises/byItem',
  GET_EVALUATIONS_BY_ITEM_ID: '/items_manager/v1/expert_evaluations/item',
  EXPERT_EVALUATIONS_MEDIA: '/items_manager/v1/expert_evaluations/media',
  ANALIZE_FINGERPRINTS: '/items_manager/v1/analyze/fingerprints',
  ANALIZE_ITEM_HISTORY: '/items_manager/v1/analyze/item',
  UPLOAD_VIDEO: '/items_manager/upload/chunk',
  USER_SUBSCRIPTION_LIMITS: '/items_manager/v1/user_subscription_limits/account',
  GET_CERTIFICATE: '/items_manager/v1/inventory/license_link/model',
  DELETE_FINGERPRINTS: '/items_manager/v1/items/fingerprints',
};

const INVENTORY = {
  GET_ALL_INVENTORY: '/items_manager/v1/inventory/search', 
  INVENTORY_BASE: '/items_manager/v1/inventory',
  ASSIGN: '/items_manager/v1/inventory/assign',
  USERS_ASSIGN: '/items_manager/v1/inventory/assign/users',
  GET_USERS: '/acc_manager/v1/admin/users',
  GET_USER: '/acc_manager/v1/users/profile',
  EDIT_USER: '/acc_manager/v1/admin/users/edit',
  CREATE_USER: '/acc_manager/v1/admin/users/create', 
  GET_ASSIGN_USER: '/items_manager/v1/inventory/assign/user',
}; 


const SUBSCRIPTION = {
  SUBSCRIBE: '/subscription_manager/v1/users/subscriptions',
  GET_SUBSCRIPTION: '/subscription_manager/v1/users/subscription_data', 
  CANCEL_SUBSCRIPTION: '/subscription_manager/v1/users/subscriptions',
}

export { PROFILE, ITEMS, INVENTORY, SUBSCRIPTION };
